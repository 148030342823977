<template>
  <div class="container page">
    <van-nav-bar :title="$t('lottery.vote')" class="nav-bar">
    </van-nav-bar>
    <div class="record">
      <!-- <div class="period">
        <van-image class="cover" :src="this.lottery.ico" style="width: 52px; height: 52px;">
          <template v-slot:loading>
            <van-loading type="spinner" />
          </template>
        </van-image>
        <span class="period-number">{{ this.lottery.now_expect }}</span>
        <div class="next-number">
          <span>{{ this.lottery.next_expect }}</span>
          <van-count-down :time="time" @finish="check()" />
        </div>
      </div> -->




      <div class="period">
        <van-image class="cover" :src="this.lottery.ico" style="width: 52px; height: 52px;">
          <template v-slot:loading>
            <van-loading type="spinner" />
          </template>
        </van-image>
        <span class="period-number" style="color: rgb(255, 255, 255); font-size: 14px;">
          {{ this.lottery.next_expect }}{{$t('lottery.period')}}
        </span>
        <div class="next-number">
          <van-count-down :time="time" @finish="check()" />
        </div>
      </div>

      <div class="numberBox" style="background: rgb(244, 124, 213); padding: 12px; margin-bottom: 10px; 
        border-radius: 8px; display: flex; align-items: center;">
        <div style="border-right: 4px solid rgb(249, 177, 231); padding: 0px 30px 0px 10px; 
          color: rgb(255, 255, 255); font-weight: bold; font-size: 16px; text-align: center;">
          <div>{{ this.lottery.now_expect }}</div>
          <div>{{$t('lottery.vote_result')}}</div>
        </div>
        <div class="resBox">
          <div class="resItem">{{ this.sum }}</div>
          <div>
            <div class="ressquareBox" style="margin: 0px 0px 5px; min-width: 70px;">{{ this.size }}</div>
            <div class="ressquareBox" style="margin: 0px;">{{ this.double }}</div>
          </div>
          <!-- <i class="van-icon van-icon-arrow-down down" style="margin-left: auto;"></i> -->
          <van-icon name="arrow-down" :class="{ up: active, down: !active }"
          @click="active ? active = false : active = true" />
        </div>
      </div>
      
      
      
      
      
      
      <!-- <div class="recent">
        <div class="kuaisan-ball left">
          <van-image class="res-img" :src="this.shanzi_1">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
          <van-image class="res-img" :src="this.shanzi_2">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
          <van-image class="res-img" :src="this.shanzi_3">
            <template v-slot:loading>
              <van-loading type="spinner" />
            </template>
          </van-image>
          <span class="res-des middle">{{ this.sum }}</span>
          <span class="res-des middle">{{ this.size }}</span>
          <span class="res-des middle">{{ this.double }}</span>
        </div>
        <van-icon name="arrow-down" :class="{ up: active, down: !active }"
          @click="active ? active = false : active = true" />
      </div> -->
    </div>
    <div class="history_popup"></div>
    <div class="wrapper">
      <div class="options-bar">
        <div class="game">
          <div class="linear-gradient"
            style="background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));">
          </div>
          <div class="sumValueTwoSides">
            <div class="rectangle large" :class="{ active: choose[v.type] }" v-for="(v, key) in lottery_peilv_list"
              :key="key" @click="choosePlay(v.type, v.name);">
              <p class="name-text large">{{ v.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="bar">
          <div class="left">
            <div class="item" @click="shopping ? shopping = false : shopping = true">
              <van-icon name="cart-o" class="jixuanico" />
              <!-- <span class="text">{{$t('lottery.task_list')}}</span> -->
            </div>
            <div class="line"></div>
          </div>
          <div class="mid">
            <!-- <span class="text">{{$t('lottery.use_balance')}}</span> -->
            <!-- <span class="text num">{{ this.userInfo.money }}{{$t('lottery.huobi')}}</span> -->
            <span class="text num">{{ parseFloat(this.userInfo.money).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }} {{$t('lottery.huobi')}}</span>
          </div>
          <div class="right" @click="jiesuan()">
            {{$t('lottery.submit')}}
          </div>
        </div>
        <div class="wrapper" :class="{ active: shopping }">
          <div class="item">
            <span class="label">{{$t('lottery.current_select_number')}}：</span>
            <div class="bet-number">{{ this.shopchoose }}</div>
            <van-icon name="arrow-down" :class="{ up: !shopping, down: shopping }"
              @click="shopping ? shopping = false : shopping = true" />
          </div>
          <div class="item">
            <span class="label">{{$t('lottery.bet_amount')}}：</span>
            <div class="amount-wrapper">
              <van-field v-model="money" type="digit" :placeholder="$t('lottery.please_enter_amount')" />
              <span class="label">{{$t('lottery.huobi')}}</span>
            </div>
          </div>
          <div class="item">
            <div class="part">
              <span>{{$t('lottery.all_bet_number')}}</span>
              <span class="number">{{ this.formData.length }}</span>
              <span>{{$t('lottery.bet')}}</span>
            </div>
            <div class="part">
              <span>{{$t('lottery.all_bet_amount')}}</span>
              <span class="number">{{ this.formData.length * this.money }}</span>
              <span>{{$t('lottery.huobi')}}</span>
            </div>

          </div>
        </div>
      </div>
      <van-popup v-model="jiesuanpage" get-container="body">
        <div class="confirm-order-modal">
          <div class="head van-hairline--bottom">
            <p class="text">{{$t('lottery.task_list')}}</p>
          </div>
          <ui class="list">
            <li v-for="(v, key) in formData" :key="key" class="lise-item van-hairline--bottom">
              <div class="main">
                <p class="bet-name">{{ v.name }}</p>
                <p class="detail-text">1{{$t('lottery.bet')}}X{{ money }}{{$t('lottery.huobi')}}={{ money }}{{$t('lottery.huobi')}}</p>
              </div>
              <van-icon @click="clearChooes(v.type)" name="close" />
            </li>
          </ui>
          <div class="sub-bar">
            <van-button class="item cancel-btn" type="default" @click="allClear()">{{$t('lottery.clear_order')}}</van-button>
            <van-button class="item sub-btn" type="default" @click="doSub">{{$t('lottery.confirm_submit')}}</van-button>
          </div>
        </div>
      </van-popup>
      <van-popup v-model="active" position="top" :style="{ height: '70%' }">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <div class="wrapper">
            <div class="item">
              <div class="left font-weight">{{$t('lottery.cycle_number')}}</div>
              <div class="right font-weight">{{$t('lottery.lottery_numbers')}}</div>
            </div>
            <div class="item" v-for="(v, key) in lottery_list" :key="key">
              <div class="left font-weight">{{ v.expect }}</div>
              <div class="right font-weight">
                <div class="kuaisan-ball left">
                  <span class="ballBox">{{v.opencode[0]}}</span>
                  <span class="ballBox">{{v.opencode[1]}}</span>
                  <span class="ballBox">{{v.opencode[2]}}</span>

                  <span class="resgardenBox">{{ v.opencode[0] + v.opencode[1] + v.opencode[2] }}</span>
                  <span class="ressquareBox">
                    {{ (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? $t('lottery.big') : $t('lottery.small')}}
                  </span>
                  <span class="ressquareBox">
                    {{ (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? $t('lottery.even') : $t('lottery.odd')}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </van-pull-refresh>
      </van-popup>
    </div>
  </div>
</template>

<script>
var time;
var count = 0;
export default {
  data() {
    return {
      jiesuanpage: false,
      choose: {
        "big": false,
        "small": false,
        "odd": false,
        "even": false,
        "3": false,
        "4": false,
        "5": false,
        "6": false,
        "7": false,
        "8": false,
        "9": false,
        "10": false,
        "11": false,
        "12": false,
        "13": false,
        "14": false,
        "15": false,
        "16": false,
        "17": false,
        "18": false,
      },
      playgame: false,
      shopping: false,
      isLoading: false,
      play: {},
      lottery_peilv_list: {},
      lottery_list: {},
      active: false,
      userInfo: {},
      lottery: {},
      shanzi_1: "0",
      shanzi_2: "0",
      shanzi_3: "0",
      sum: 0,
      size: "",
      double: "",
      time: 0,
      shopchoose: this.$t('lottery.not_selected'),
      gameitem: "",
      formData: [],
      money: "",
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    doSub() {
      if (this.money === "0") {
        this.$toast(this.$t('lottery.amount_error'));
        return false;
      }
      if (this.formData.length === 0) {
        this.$toast(this.$t('lottery.please_select_gameitem'));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t('lottery.please_enter_amount'));
        return false;
      } else {
        if (this.userInfo.money - (this.money * this.formData.length) < 0) {
          this.$toast(this.$t('lottery.not_enough_balance'));
          return false;
        } else {
          this.$http({
            method: 'post',
            data: {
              item: this.gameitem,
              money: this.money,
              lid: this.lottery.id,
              mid: this.userInfo.id,
              expect: this.lottery.now_expect
            },
            url: 'game_place_order'
          }).then(res => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.allClear();
              this.getUserInfo();
            } else if (res.code === 401) {
              this.$toast(res.msg);
            }
          })
          return true;
        }
      }
    },
    allClear() {
      for (var i = 0; i < this.formData.length; i++) {
        this.choose[this.formData[i]['type']] = false;
      }
      this.formData.length = 0;
      this.money = "";
      this.shopchoose = this.$t('lottery.not_selected');
      this.gameitem = "";
      this.shopping = false;
      this.jiesuanpage = false;
    },
    jiesuan() {
      if (this.formData.length === 0) {
        this.$toast(this.$t('lottery.please_select_gameitem'));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t('lottery.please_enter_amount'));
        return false;
      } else {
        this.jiesuanpage ? this.jiesuanpage = false : this.jiesuanpage = true;
      }

    },
    clearChooes(type) {
      for (var i = 0; i < this.formData.length; i++) {
        if (type === this.formData[i]['type']) {
          this.formData.splice(i, 1)
          this.choose[type] = false;
        }
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]['name'];
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.formData[j]['name'];
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t('lottery.not_selected');
        this.gameitem = "";
        this.shopping = false;
      }
      if (this.formData.length === 0) {
        this.jiesuanpage = false;
      }
    },
    choosePlay(type, name) {
      if (this.choose[type] === true) {
        this.choose[type] = false;
        for (var i = 0; i < this.formData.length; i++) {
          if (type === this.formData[i]['type']) {
            this.formData.splice(i, 1)
          }
        }
      } else if (this.choose[type] === false) {
        this.formData.push({ 'name': name, 'type': type })
        this.choose[type] = true;
      }
      if (this.formData.length === 1) {
        this.shopping = true;
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]['name'];
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.formData[j]['name'];
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t('lottery.not_selected');
        this.gameitem = "";
        this.shopping = false;
      }

    },
    check() {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        time = window.setInterval(() => {
          setTimeout(() => {
            this.getUserInfo();
            this.getLotteryInfo();
            this.getLotteryList();
            count++;
            if (count > 5) {
              clearInterval(time);
              count = 0;
            }
          }, 0)
        }, 300)
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('fresh_success'));
        this.getLotteryList();
        this.isLoading = false;
      }, 200);
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryPeilv() {
      this.$http({
        method: 'get',
        // data: { id: this.$route.query.id },旧
        data: { id: 11 },
        url: 'lottery_get_peilv'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_peilv_list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryList() {
      this.$http({
        method: 'get',
        data: { key: 'hookup' },
        url: 'lottery_get_one_list'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_list = res.data;
          this.getLotteryPeilv();
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryInfo() {
      this.$http({
        method: 'get',
        // data: { key: this.$route.query.key },
        data: { key: 'hookup' },
        url: 'lottery_get_info'
      }).then(res => {
        if (res.code === 200) {
          if (parseFloat(this.userInfo.money) < parseFloat(res.data.condition)) {
            this.$toast("{{$t('lottery.get_task')}}");
            this.$router.push({ path: '/Home' })
            return false;
          }
          this.lottery = res.data;
          this.time = res.data.second * 1000;

          if (this.time / 1000 === 59) {
            this.$toast("$t('lottery.lottery_result')" + this.lottery.now_expect);
          }
          this.shanzi_1 = "img/lottery/shaizi/" + res.data?.opencode[0] + ".png";
          this.shanzi_2 = "img/lottery/shaizi/" + res.data?.opencode[1] + ".png";
          this.shanzi_3 = "img/lottery/shaizi/" + res.data?.opencode[2] + ".png";
          this.sum = res.data.opencode[0] + res.data.opencode[1] + res.data.opencode[2];
          if (this.sum >= 11 && this.sum <= 18) {
            this.size = this.$t('lottery.big');
          } else if (this.sum >= 3 && this.sum <= 10) {
            this.size = this.$t('lottery.small');
          }
          if (this.sum % 2 === 0) {
            this.double = this.$t('lottery.even');
          } else {
            this.double = this.$t('lottery.odd');
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })

    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo();
      this.getLotteryInfo();
      this.getLotteryList();

    }
  },
  destroyed() {
    clearInterval(time);
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.page{
  background: url(/img/lottery/lotterybg.png) no-repeat top;
  background-size: contain;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 2;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  -webkit-overflow-scrolling: touch;
  background-color: #f2f2f5;
}

.nav-bar {
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  font-size: 28px;
  line-height: 60px;
  background: linear-gradient(90deg, rgba(223, 53, 173, .8), rgba(79, 29, 181, .8)) ;
}

.record {
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0 2px 2px 0 #cacaca;
    z-index: 1;
    background: linear-gradient(90deg, rgba(223, 53, 173, .8), rgba(79, 29, 181, .8));
}

.record .period {
    display: flex;
    align-items: center;
    padding: 40px 0;
}

.record .period .cover {
    width: 8vw;
    height: 8vw;
    -o-object-fit: cover;
    object-fit: cover;
}

.record .period .period-number {
    flex: 1;
    margin-left: 20px;
    margin-right: 10px;
    height: 7vw;
    line-height: 7vw;
    font-size: 5vw;
    font-weight: 700;
    color: #000;
}
.van-image__error, .van-image__img, .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
}




.van-count-down {
    color: #f4d70a;
    font-size: 6vw;
    margin-top: 10px;
    float: right;
}

.record .recent {
  display: flex;
  align-items: center;
  height: 110px;
}


.resBox {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 8px;
    height: 100%;
    margin-left: 5px;
    width: calc(100% - 37vw);
    box-sizing: border-box;
    justify-content: space-around;
}
.resBox .resItem {
    width: 9vw;
    height: 9vw;
    line-height: 9vw;
    margin-right: 10px;
    background: linear-gradient(90deg, #8b73e6, #d75db4);
    border-radius: 50%;
    color: #fff;
    text-align: center;
}
.ressquareBox {
    border-radius: 8px;
    padding: 0 8px;
}
.resgardenBox, .ressquareBox {
    background: linear-gradient(90deg, #f560cd, #4f2b8a);
    color: #fff;
    height: 7vw;
    line-height: 7vw;
    text-align: center;
    font-size: 3vw;
    font-weight: 700;
    margin-right: 15px;
}
.down {
    transition: all .5s;
}
.up {
    transform: rotate(180deg);
    transition: all .5s;
}
.van-icon {
    font-size: 5vw;
}



.kuaisan-ball .left {
  justify-content: flex-start;
}

.kuaisan-ball {
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
}

// .kuaisan-ball .res-des {
//   font-weight: 700;
//   text-align: center;
//   color: #000;
// }

// .kuaisan-ball .res-des.middle {
//   width: 15%;
//   font-size: 35px;
// }

.van-icon {
  font-size: 40px;
}

.down {
  transition: all .5s;
}

.up {
  transform: rotate(180deg);
  transition: all .5s;
}

.wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.options-bar {
  display: flex;
  align-items: center;
  height: calc(100% - 210px);
  background: #f2f3f5;
}

.options-bar .game {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.options-bar .game .tips {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 20px;
}

.options-bar .game .tips .odds {
  flex: 1;
  font-size: 35px;
  font-weight: 500;
  color: #ff253f;
}

.options-bar .game .tips .play-tip {
  display: flex;
  align-items: center;
  height: 100%;
}

::v-deep .van-icon-more-o {
  color: #ff253f;
  font-size: 50px;
}

.options-bar .game .tips .play-tip .span-text {
  margin-left: 10px;
  font-size: 35px;
  font-weight: bolder;
  color: #ff253f;
}
.ballBox {
    color: #d75db4;
    font-weight: 700;
    margin-right: 18px;
    font-size: 5vw;
}
.resgardenBox, .ressquareBox {
    background: linear-gradient(90deg, #f560cd, #4f2b8a);
    color: #fff;
    height: 7vw;
    line-height: 7vw;
    text-align: center;
    font-size: 3vw;
    font-weight: 700;
    margin-right: 25px;
}
.resgardenBox {
    width: 7vw;
    border-radius: 50%;
}
// .linear-gradient {
//   width: 100%;
//   height: 2px;
// }

.sumValueTwoSides {
  display: flex;
  padding: 4vw 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.sumValueTwoSides>div {
    width: calc(50% - 7vw) !important;
    height: 21vw !important;
    line-height: 21vw;
    text-align: center;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.sumValueTwoSides>div p {
    margin: 0;
}
.sumValueTwoSides .rectangle.active {
    background: #d07ac7;
    color: #fff;
}






.rectangle {
  overflow: hidden;
}

.rectangle.large {
  margin: 0 0 30px 4%;
  width: 20%;
  border-radius: 10px;
}

.rectangle .wrapper {
  position: relative;
  padding: 0 10px;
  background: #fff;
}

.rectangle .wrapper .content {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.rectangle.large .wrapper {
  padding-bottom: 100%;
}

.rectangle .wrapper .content .name-text.large {
  font-size: 45px;
}

.rectangle .wrapper .content .name-text {
  color: #7d7c7c;
  font-weight: bolder;
}

.rectangle .wrapper .content .odd-text.large {
  font-size: 25px;
  margin-top: -30px;
}

.rectangle .wrapper .content .odd-text {
  text-align: center;
  color: #ff253f;
}

.bottom-bar {
  position: absolute;
  left: 0;
  right: 0;
  // bottom: 0;
  height: 100px;
  z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 #cacaca;
  z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .bar .left .item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  font-size: 20px;
}

.bottom-bar .bar .left .item .text {
  font-size: 22px;
  color: #7d7c7c;
}

.jixuanico {
  font-size: 45px;
}

.bottom-bar .bar .left .line {
  width: 2px;
  height: 50px;
  background: #dadada;
}

.bottom-bar .bar .mid {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
}

.bottom-bar .bar .mid .text {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}

.bottom-bar .bar .mid .text.num {
  margin: 0 5px;
  color: #ff253f;
}

.bottom-bar .bar .right {
  padding: 0 30px;
  margin: 0 30px;
  color: #fff;
  background: linear-gradient(20deg, #8673e5, #d958a4);
  // font-size: 40px;
  font-weight: 500;
  height: 70px;
  line-height: 70px;
  border-radius: 20px;
}

.rectangle.active .wrapper {
  background-color: #ff253f !important;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-popup {
  position: absolute;
}

::v-deep .van-overlay {
  position: absolute;
  background-color: rgb(70 67 67 / 70%);
}

::v-deep .van-popup--top {
  top: -1px;
}

.wrapper .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.wrapper .item .left {
  width: 38%;
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  color: #000;
}

.font-weight {
  font-weight: 700 !important;
}

.wrapper .item .right {
  flex: 1;
  display: flex;
  font-size: 30px;
  justify-content: center;
  overflow: hidden;
  color: #000;
}

.wrapper .item .kuaisan-ball .left {
  justify-content: flex-start;
}

.wrapper .item .kuaisan-ball {
  margin-left: 20px;
  flex: 1;
  display: flex;
  align-items: center;
}

.wrapper .item .kuaisan-ball .res-img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.wrapper .item .kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.wrapper .item .kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 35px;
}

.play-type-tip {
  position: unset;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 650px;
  height: 700px;
  max-height: 50%;
  z-index: 10;
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;
  color: #000;
}

.play-type-tip .title {
  line-height: 90px;
  background: linear-gradient(90deg, #7e5678, #e6c3a1);
  text-align: center;
  color: #fff;
  font-size: 35px;
  font-weight: 500;
}

.mask {
  background-color: rgb(0 0 0 / 0%);
  animation-duration: 0.35s;
}

.play-type-tip .wrapper {
  height: calc(100% - 10px);
  background-color: transparent;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.play-type-tip .wrapper .item {
  padding: 40px 50px;
  display: flex;
  align-items: flex-start;
}

.play-type-tip .wrapper .item .van-icon {
  color: #e6c3a1;
  font-size: 60px;
}

.play-type-tip .wrapper .item .content .content-title {
  margin-top: 22px;
  font-size: 35px;
  font-weight: 500;
  color: #000;
  line-height: 0px;
}

.play-type-tip .wrapper .item .content .content-detail {
  margin-top: 5px;
  font-size: 22px;
  color: #000;
  line-height: 30px;
}

.play-type-tip .wrapper .item .content {
  flex: 1;
  margin-left: 30px;
}

.rectangle.active .wrapper {
  background-color: #ff253f !important;
}

.rectangle.active .wrapper .name-text,
.rectangle.active .wrapper .odd-text {
  color: #fff !important;
}

.bottom-bar .wrapper {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  padding: 20px 20px 10px 20px;
  height: 230px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 10px 0 #cacaca;
  transition: transform .3s cubic-bezier(.21, 1.02, .55, 1.01);
}

.bottom-bar .wrapper.active {
  transform: translateY(-100%);
}

.bottom-bar .wrapper .item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 65px;
}

.bottom-bar .wrapper .item .label {
  font-size: 30px;
  line-height: 30px;
  color: #000;
}

.bottom-bar .wrapper .item .bet-number {
  flex: 1;
  margin: 0 16px;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  color: #ff253f;
  font-size: 30px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
}

.bottom-bar .wrapper .item .amount-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.van-cell {
  font-size: 30px;
  line-height: 50px;
}

.bottom-bar .wrapper .item .part {
  margin-right: 20px;
}

.bottom-bar .wrapper .item .part span {
  font-size: 30px;
  vertical-align: center;
  color: #000;
}

.bottom-bar .wrapper .item .part .number {
  margin: 0 5px;
  color: #ff253f;
  font-weight: 500;
}

::v-deep .van-field__control {
  color: #ff253f;
}

.confirm-order-modal {
  position: unset;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 20px 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 610px;
  height: 680px;
  max-height: 50%;
  z-index: 10;
  background-color: #fff;
  border-radius: 30px;
}

.confirm-order-modal .head {
  position: relative;
  height: 80px;
}

.confirm-order-modal .head .text {
  padding: 0 20px;
  height: 30px;
  line-height: 10px;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  color: #7e5678;
}

::v-deep .confirm-order-modal .van-hairline--bottom::after {
  border-bottom-width: 2px;
}

.van-popup--center {
  border-radius: 30px;
}

.confirm-order-modal .list {
  flex: 1;
  padding: 0 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.confirm-order-modal .list .lise-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.confirm-order-modal .list .lise-item .main {
  flex: 1;
  overflow: hidden;
}

.confirm-order-modal .list .lise-item .main .bet-name {
  color: #ff253f;
  font-size: 35px;
  font-weight: 500;
  line-height: 0px;
  word-wrap: break-word;
  word-break: break-all;
}

.confirm-order-modal .list .lise-item .main .detail-text {
  line-height: 0px;
  font-size: 25px;
  color: #979799;
}

.confirm-order-modal .list .lise-item {
  color: #ff253f;
}

.confirm-order-modal .sub-bar {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-around;
}

.confirm-order-modal .sub-bar .item {
  min-width: 40%;
  height: 80px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 35px;
  font-weight: 500;
}

.confirm-order-modal .sub-bar .item.cancel-btn {
  border: 2px solid #979799;
  color: #979799;
  background-color: #fff;
}

.confirm-order-modal .sub-bar .item.sub-btn {
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
  color: #fff;
  border: 0;
}

.next-number span {
  font-size: 35px;
  font-weight: 700;
  color: #000;
  float: right;
}
</style>
